import React from 'react'
import {IbeConfig, UnitGroup} from '../../types'
import ImageCarousel from '../common/ImageCarousel'
import SearchUnitAttributes from './SearchUnitAttributes'

export interface SearchUnitDetailsModalProps {
  config: IbeConfig
  unitGroup: UnitGroup
  isOpen: boolean
  onClose: () => void
}

const SearchUnitDetailsModal = ({
  config,
  unitGroup,
  isOpen,
  onClose
}: SearchUnitDetailsModalProps) => {
  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box w-full p-0">
            <div className="w-full h-72 rounded-t-2xl overflow-hidden">
              <ImageCarousel />
            </div>
            <button
              className="btn btn-sm btn-circle btn-white absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>
            <div className="modal-body overflow-y-scroll max-h-96 pr-4 mt-2 px-8 py-4">
              <h2 className="font-bold text-xl mb-2">{unitGroup.name}</h2>
              <SearchUnitAttributes />
              <p className="my-2">{unitGroup.description}</p>
              <h3 className="font-bold text-cabana uppercase mt-4 mb-2">
                Features
              </h3>
              <ul className="list-disc px-5 grid grid-cols-2 gap-0">
                <li>King Bed</li>
                <li>Fast WiFi</li>
                <li>In-unit laundry</li>
                <li>Air conditioning</li>
                <li>Workspace</li>
                <li>Chef-ready kitchen</li>
                <li>55" TV</li>
                <li>Full refrigerator</li>
                <li>Apple TV with streaming</li>
              </ul>
              <div className="modal-action">
                <button className="btn" onClick={onClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SearchUnitDetailsModal
