import React from 'react'
import Badge from '../common/Badge'
import CheckoutPaymentForm from './CheckoutPaymentForm'
import {
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery
} from '../../services/adyenPaymentsApi'
import {useAppSelector} from '../../store/hooks'
import {selectCartItemsProperty} from '../../store/shoppingCartSlice'
import {selectBooker, selectBookerEmail} from '../../store/bookerSlice'
import {selectBookingMetadata} from '../../store/selectors'

export interface CheckoutPaymentProps {
  isActive: boolean
  onPaymentProcessing: (paymentSessionId: string) => void
}

const CheckoutPayment = ({
  isActive,
  onPaymentProcessing
}: CheckoutPaymentProps) => {
  // TODO: replace with real propertyId
  // const propertyId = useAppSelector(selectCartItemsProperty)
  const propertyId = 'TEST'

  const booker = useAppSelector(selectBooker)
  const paymentMethodsQuery = useGetPaymentMethodsQuery(propertyId as string, {
    skip: !isActive || !propertyId
  })
  const paymentMethods = paymentMethodsQuery?.data
  const bookingMetadata = useAppSelector(selectBookingMetadata)
  const paymentSessionQuery = useGetPaymentSessionQuery(
    {
      amountInCents: 0,
      isPaymentAccount: true,
      metadata: bookingMetadata,
      propertyId,
      returnUrl: window.location.href,
      shopperEmail: booker.email
    },
    {
      skip: !isActive || !propertyId
    }
  )
  const paymentSession = paymentSessionQuery?.data

  console.log('paymentMethodsQuery', paymentMethodsQuery)
  console.log('paymentSessionQuery', paymentSessionQuery)

  return (
    <div className="border border-gray-300 rounded-xl p-4">
      <div className="flex flex-row gap-4">
        <Badge label="2" />
        <h1 className="font-bold">Payment</h1>
      </div>
      {isActive && paymentMethods && paymentSession ? (
        <div className="mt-4 flex flex-col gap-1">
          <CheckoutPaymentForm
            paymentMethods={paymentMethods}
            paymentSession={paymentSession}
            onPaymentCompleted={(result: any) => {
              const paymentSessionId =
                paymentSession?.metadata?.paymentSessionId
              console.log('Payment completed', paymentSessionId, result)
              onPaymentProcessing(paymentSessionId)
            }}
          />
        </div>
      ) : (
        ''
      )}

      {isActive && (!paymentMethods || !paymentSession) ? (
        <div className="my-8 flex flex-row gap-2 items-center">
          <span className="loading loading-spinner loading-sm"></span>
          <h2 className="text-sm">Creating secure payment session...</h2>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default CheckoutPayment
