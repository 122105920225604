import {faShoppingCart} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React from 'react'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectCartItemCount, showCart} from '../../store/shoppingCartSlice'

export interface ShoppingCartButtonProps {
  className?: string
}

const ShoppingCartButton = ({className}: ShoppingCartButtonProps) => {
  const dispatch = useAppDispatch()
  const cartItemCount = useAppSelector(selectCartItemCount)

  const CartButton = (
    <button
      className={clsx('btn btn-outline flex items-center', className)}
      onClick={() => dispatch(showCart())}
    >
      <FontAwesomeIcon icon={faShoppingCart} />
      <span>Cart</span>
    </button>
  )

  if (cartItemCount === 0) {
    return CartButton
  } else {
    return (
      <div className="indicator">
        <span className="indicator-item badge badge-secondary">
          {cartItemCount}
        </span>
        {CartButton}
      </div>
    )
  }
}

export default ShoppingCartButton
