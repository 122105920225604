import {format, isDate, parseISO} from 'date-fns'
import {Offer, OfferResult, PropertyConfig, UnitGroupOffer} from '../types'
import currency from 'currency.js'

export function formatPropertyAddress(
  propertyConfig: PropertyConfig
): string | null {
  if (!propertyConfig.address) {
    return null
  }
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode
  } = propertyConfig.address

  if (state === 'DC') {
    return `${addressLine1}${
      addressLine2 ? `, ${addressLine2}` : ''
    }, ${city} ${postalCode}`
  } else {
    return `${addressLine1}${
      addressLine2 ? `, ${addressLine2}` : ''
    }, ${city}, ${state} ${postalCode}`
  }
}

/**
 * Gets a map consisting of property id/config entries
 * @param propertyConfigs
 * @returns
 */
export function getPropertiesById(
  propertyConfigs: PropertyConfig[]
): Record<string, PropertyConfig> {
  return propertyConfigs.reduce(
    (acc: Record<string, PropertyConfig>, property: PropertyConfig) => {
      acc[property.id] = property
      return acc
    },
    {}
  )
}

/**
 * Gets a property config by id
 * @param propertyConfigs
 * @param propertyId
 * @returns
 */
export function getPropertyById(
  propertyConfigs: PropertyConfig[],
  propertyId: string
): PropertyConfig | undefined {
  return propertyConfigs.find(property => property.id === propertyId)
}

/**
 * Gets a list of property configs by location
 * @param propertyConfigs
 * @param locationId
 * @returns
 */
export function getPropertiesByLocation(
  propertyConfigs: PropertyConfig[],
  locationId: string
): PropertyConfig[] {
  return locationId === 'ALL'
    ? propertyConfigs
    : propertyConfigs.filter(property => property.location === locationId)
}

/**
 * Gets the best offer from a list of offers
 * @param offers
 * @returns
 */
export function getBestOffer(offers: Offer[]): Offer {
  return offers.reduce((cheapest, offer) =>
    offer.totalGrossAmount.amount < cheapest.totalGrossAmount.amount
      ? offer
      : cheapest
  )
}

/**
 * Gets an offer result by property id
 * @param offerResults
 * @param propertyId
 * @returns
 */
export function getOfferResultByPropertyId(
  offerResults: OfferResult[],
  propertyId: string
): OfferResult | undefined {
  return offerResults.find(
    offerResult => offerResult.property.id === propertyId
  )
}

/**
 * Groups offers by unit group for display
 * @param offers
 * @returns
 */
export function groupOffersByUnitGroup(offers: Offer[]): UnitGroupOffer[] {
  // group offers by unit group, calculate lowest gross amount and nightly amount
  const unitGroupOffersMap = offers.reduce(
    (acc: Record<string, UnitGroupOffer>, offer: Offer) => {
      const unitGroupId = offer.unitGroup.id
      const nightCount = offer.timeSlices.length
      if (acc[unitGroupId]) {
        const unitGroupAcc = acc[unitGroupId]
        unitGroupAcc.offers.push(offer)
        if (
          unitGroupAcc.lowestGrossAmount.amount > offer.totalGrossAmount.amount
        ) {
          unitGroupAcc.lowestGrossAmount = offer.totalGrossAmount
          unitGroupAcc.lowestNightlyAmount.amount = Math.round(
            offer.totalGrossAmount.amount / nightCount
          )
          unitGroupAcc.lowestNightlyAmount.currency =
            offer.totalGrossAmount.currency
        }
      } else {
        acc[unitGroupId] = {
          unitGroup: offer.unitGroup,
          availableUnits: offer.availableUnits,
          lowestGrossAmount: offer.totalGrossAmount,
          lowestNightlyAmount: {
            amount: Math.round(offer.totalGrossAmount.amount / nightCount),
            currency: offer.totalGrossAmount.currency
          },
          nights: nightCount,
          offers: [offer]
        }
      }
      return acc
    },
    {}
  )

  // sort by lowest gross amount
  const unitGroupOffers = Object.values(unitGroupOffersMap).sort((a, b) => {
    if (a.lowestGrossAmount.amount < b.lowestGrossAmount.amount) {
      return -1
    } else if (a.lowestGrossAmount.amount > b.lowestGrossAmount.amount) {
      return 1
    } else {
      return 0
    }
  })

  return unitGroupOffers
}

/**
 * Generates a UUID
 * @returns
 */
export function generateUUID(): string {
  const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  return template.replace(/[xy]/g, c => {
    var r = (Math.random() * 16) | 0
    var v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * Formats a date
 * @param date
 * @returns
 */
export function formatDate(d: Date | string): string {
  const date = isDate(d) ? (d as Date) : parseISO(d as string)
  return format(date, 'MMM dd, yyyy')
}

/**
 * Formats a currency amount
 * @param value
 * @returns
 */
export function formatCurrency(value: any): string {
  return currency(value, {separator: ',', symbol: '$'}).format()
}

export function safeParseISO(dateString: any): Date | null {
  try {
    return dateString ? parseISO(dateString) : null
  } catch (e) {
    console.warn('Failed to parse date', dateString, e)
    return null
  }
}
