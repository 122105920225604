import React from 'react'
import {
  faBathtub,
  faBed,
  faPerson,
  faRulerCombined
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const SearchUnitAttributes = () => {
  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-0.5 text-sm text-cabana">
        <div>
          <FontAwesomeIcon icon={faBed} className="mr-1" />
          <span>2 Bedrooms</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faBathtub} className="mr-1" />
          <span>1 Bathroom</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faPerson} className="mr-1" />
          <span>4 Guests</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faRulerCombined} className="mr-1" />
          <span>950 sq. ft.</span>
        </div>
      </div>
    </div>
  )
}

export default SearchUnitAttributes
