import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getConfig} from './config'
import {OfferRequest, ServiceOfferRequest} from '../types'
import {format, parseISO} from 'date-fns'

const {ibeApiUrl} = getConfig()

export const ibeApi = createApi({
  reducerPath: 'ibeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ibeApiUrl
  }),
  tagTypes: ['Config', 'Offers', 'ServiceOffers'],
  endpoints: builder => ({
    getConfig: builder.query({
      query() {
        return '/config'
      },
      providesTags: ['Config'],
      keepUnusedDataFor: 120000
    }),
    searchOffers: builder.query({
      query(params: OfferRequest) {
        const dateRange = params.dateRange
        const startDate = parseISO(dateRange.start as string)
        const endDate = parseISO(dateRange.end as string)
        return {
          url: `/offers`,
          method: 'POST',
          body: {
            ...params,
            dateRange: {
              ...dateRange,
              start: format(startDate, 'yyyy-MM-dd'),
              end: format(endDate, 'yyyy-MM-dd')
            }
          }
        }
      },
      providesTags: ['Offers']
    }),
    searchServiceOffers: builder.query({
      query(params: ServiceOfferRequest) {
        const dateRange = params.dateRange
        const startDate = parseISO(dateRange.start as string)
        const endDate = parseISO(dateRange.end as string)
        return {
          url: `/service-offers`,
          method: 'POST',
          body: {
            ...params,
            dateRange: {
              ...dateRange,
              start: format(startDate, 'yyyy-MM-dd'),
              end: format(endDate, 'yyyy-MM-dd')
            }
          }
        }
      },
      providesTags: ['ServiceOffers']
    })
  })
})

export const {
  useGetConfigQuery,
  useSearchOffersQuery,
  useLazySearchOffersQuery,
  useSearchServiceOffersQuery
} = ibeApi
