import React from 'react'
import {IbeConfig, OfferResult, PropertyConfig} from '../../types'
import SearchUnitResultItem from './SearchUnitResultItem'
import {
  getOfferResultByPropertyId,
  getPropertyById,
  groupOffersByUnitGroup
} from '../../services/utils'

export interface SearchUnitResultsProps {
  config: IbeConfig
  offerResults?: OfferResult[]
  selectedPropertyId: string
  isLoading: boolean
  onSelectRoom: (room: any) => void
}

const SearchUnitResults = ({
  config,
  offerResults = [],
  selectedPropertyId,
  isLoading
}: SearchUnitResultsProps) => {
  const selectedProperty = getPropertyById(
    config.properties,
    selectedPropertyId
  )
  const selectedPropertyOfferResult =
    selectedPropertyId && offerResults && offerResults?.length > 0
      ? getOfferResultByPropertyId(offerResults, selectedPropertyId)
      : null

  const offers = selectedPropertyOfferResult?.offers || []
  const unitGroupOffers = groupOffersByUnitGroup(offers)

  if (isLoading) {
    return (
      <div className="flex-1 flex flex-col gap-8 mt-8">
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
      </div>
    )
  } else if (unitGroupOffers?.length) {
    return (
      <>
        <div className="text-lg mt-4">
          Showing {unitGroupOffers.length} room results for{' '}
          <span className="font-bold">{selectedProperty?.name}</span>:
        </div>
        <div className="flex flex-col gap-8 mt-4 mb-8">
          {unitGroupOffers.map((unitGroupOffer, index) => {
            return (
              <SearchUnitResultItem
                key={index}
                config={config}
                unitGroupOffer={unitGroupOffer}
                propertyId={selectedPropertyId}
              />
            )
          })}
        </div>
      </>
    )
  } else {
    return
  }
}

export default SearchUnitResults
