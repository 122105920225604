import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppView} from '../types'

export const appViewSlice = createSlice({
  name: 'appView',
  // initialState: AppView.HOME,
  initialState: AppView.CHOOSE_PROPERTY,
  reducers: {
    setAppView: (state, action: PayloadAction<AppView>) => {
      state = action.payload
      return state
    }
  }
})

// selectors
export const selectAppView = (state: any): AppView => {
  return state.appView
}

export const selectIsSearchView = (state: any): boolean => {
  return [AppView.HOME, AppView.CHOOSE_PROPERTY, AppView.CHOOSE_UNIT].includes(
    state.appView
  )
}

// actions
export const {setAppView} = appViewSlice.actions

// reducer
export default appViewSlice.reducer
