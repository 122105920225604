import React from 'react'

export interface BadgeProps {
  label: string
}

const Badge = ({label}: BadgeProps) => {
  return (
    <div className="w-6 h-6 flex justify-center items-center bg-cabana rounded-full">
      <span className="text-white font-semibold text-sm">{label}</span>
    </div>
  )
}

export default Badge
