// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../../node_modules/react-phone-input-2/lib/style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input .form-control{

    width: 100%;

    border-radius: 0.5rem;

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(209 213 219 / var(--tw-border-opacity));

    background-color: transparent
}

.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag{

    border-top-left-radius: 0.5rem;

    border-bottom-left-radius: 0.5rem
}
`, "",{"version":3,"sources":["webpack://./src/styles/phone-input.css"],"names":[],"mappings":"AAGE;;IAAA,WAA8D;;IAA9D,qBAA8D;;IAA9D,iBAA8D;;IAA9D,sBAA8D;;IAA9D,yDAA8D;;IAA9D;AAA8D;;AAK9D;;;IAAA,8BAAmB;;IAAnB;AAAmB","sourcesContent":["@import 'react-phone-input-2/lib/style.css';\n\n.react-tel-input .form-control {\n  @apply w-full rounded-lg border border-gray-300 bg-transparent;\n}\n\n.react-tel-input .flag-dropdown,\n.react-tel-input .selected-flag {\n  @apply rounded-l-lg;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
