import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import PhoneInput from 'react-phone-input-2'
import {z} from 'zod'
import clsx from 'clsx'
import {MemberState} from '../../store/memberSlice'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons'

const GuestInfoSchema = z.object({
  firstName: z.string().min(1, 'First Name is required'),
  lastName: z.string().min(1, 'Last Name is required'),
  phone: z.string().min(7, 'Mobile Phone is required'),
  email: z.string().min(1, 'Email is required').email(),
  comments: z.string().optional()
})

export type GuestInfo = z.infer<typeof GuestInfoSchema>

export interface CheckoutGuestInfoFormProps {
  className?: string
  isEditMode: boolean
  booker: GuestInfo
  member: MemberState
  onBack: () => void
  onSave: (guestInfo: GuestInfo) => void
  onSignOut: () => void
  onCancel: () => void
}

const CheckoutGuestInfoForm = ({
  className = '',
  isEditMode,
  booker,
  member,
  onBack,
  onSave,
  onCancel,
  onSignOut
}: CheckoutGuestInfoFormProps) => {
  const {register, handleSubmit, control, formState} = useForm<GuestInfo>({
    defaultValues: {
      firstName: booker?.firstName || member?.firstName || '',
      lastName: booker?.lastName || member?.lastName || '',
      phone: booker?.phone || member?.phone || '',
      email: booker?.email || member?.email || '',
      comments: booker?.comments || ''
    },
    resolver: zodResolver(GuestInfoSchema)
  })

  const {errors} = formState

  return (
    <form
      className={clsx('flex flex-col gap-1', className)}
      onSubmit={handleSubmit((guestInfo: GuestInfo) => {
        onSave(guestInfo)
      })}
    >
      <div className="flex flex-row items-center gap-2">
        <span className="font-semibold text-lg flex-1">
          Hello, {member?.isAuthenticated ? member?.firstName : 'Guest'}!
        </span>
        {member.isAuthenticated && (
          <a
            className="link link-primary text-sm flex-0"
            onClick={() => {
              onSignOut()
            }}
          >
            sign out
          </a>
        )}
      </div>

      <p className="mb-2">Please provide your contact information.</p>
      <label className="form-control">
        <div className="label">
          <span className="label-text">First Name*</span>
        </div>
        <input
          type="text"
          className="input input-bordered input-sm"
          {...register('firstName')}
        />
        {errors.firstName ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              {errors.firstName.message}
            </span>
          </div>
        ) : (
          ''
        )}
      </label>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Last Name*</span>
        </div>
        <input
          type="text"
          className="input input-bordered input-sm"
          {...register('lastName')}
        />
        {errors.lastName ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              {errors.lastName.message}
            </span>
          </div>
        ) : (
          ''
        )}
      </label>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Mobile Phone*</span>
        </div>
        <Controller
          name="phone"
          control={control}
          render={({field}) => {
            return (
              <PhoneInput
                country={'us'}
                value={field.value}
                onChange={(phone, country, e, formattedValue) => {
                  // console.log('phone onChange', {phone, country, e, formattedValue})
                  field.onChange(phone)
                }}
              />
            )
          }}
        />

        {errors.phone ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              {errors.phone.message}
            </span>
          </div>
        ) : (
          ''
        )}
      </label>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Email*</span>
        </div>
        <input
          type="text"
          className="input input-bordered input-sm"
          {...register('email')}
        />
        {errors.email ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              {errors.email.message}
            </span>
          </div>
        ) : (
          ''
        )}
      </label>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Comments about your stay</span>
        </div>
        <textarea
          className="textarea textarea-bordered"
          {...register('comments')}
        ></textarea>
      </label>

      {isEditMode ? (
        <div className="flex justify-center gap-2 w-full mt-2">
          <button
            className="btn btn-ghost mt-4"
            onClick={() => {
              onCancel()
            }}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-secondary mt-4">
            Update
          </button>
        </div>
      ) : (
        <button type="submit" className="btn btn-secondary mt-4">
          Continue
        </button>
      )}

      {!member.isAuthenticated && !isEditMode && (
        <div className="flex justify-center w-full mt-2">
          <a
            className="link link-primary text-sm"
            onClick={() => {
              onBack()
            }}
          >
            <FontAwesomeIcon icon={faCaretLeft} className="mr-1" />
            <span>back</span>
          </a>
        </div>
      )}
    </form>
  )
}

export default CheckoutGuestInfoForm
