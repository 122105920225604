import React from 'react'
import * as types from '../../types'
import clsx from 'clsx'
import {formatCurrency, formatDate} from '../../services/utils'
import {
  calcTotalCharges,
  calcTotalPrepaymentAmount,
  calcTotalRoomCharges,
  calcTotalServiceCharges,
  calcTotalTaxesAndFees,
  getAllTaxesAndFees
} from '../../services/offerUtils'

export interface ShoppingCartPricingDetailsProps {
  item: types.ShoppingCartItem
  showFullDetails?: boolean
  className?: string
}

const ShoppingCartPricingDetails = ({
  item,
  showFullDetails = false,
  className
}: ShoppingCartPricingDetailsProps) => {
  // const services = getAdditionalServices(item.offer, item.additionalServices)
  const {offer, additionalServices} = item
  const fees = getAllTaxesAndFees(offer, [])

  const totalRoomCharges = calcTotalRoomCharges(item.offer)
  const totalServiceCharges = calcTotalServiceCharges(additionalServices)
  const totalTaxesAndFees = calcTotalTaxesAndFees(item.offer, [])
  const totalPrepayment = calcTotalPrepaymentAmount(offer, additionalServices)
  const totalCharges = calcTotalCharges(item.offer, item.additionalServices, [])

  return (
    <div className={clsx('w-full flex flex-col items-end gap-0.5', className)}>
      <table className="price-details">
        <tbody>
          {showFullDetails && (
            <>
              <tr className="price-group-header">
                <td colSpan={2}>Room Charges</td>
              </tr>
              {item.offer?.timeSlices?.map(timeSlice => {
                return (
                  <tr key={timeSlice.from}>
                    <td>{formatDate(timeSlice.from)}</td>
                    <td className="price">
                      {formatCurrency(timeSlice.baseAmount.grossAmount)}
                    </td>
                  </tr>
                )
              })}
              {additionalServices.length > 0 && (
                <>
                  <tr className="price-group-header">
                    <td colSpan={2}>Service Charges</td>
                  </tr>
                  {additionalServices.map(service => {
                    return (
                      <tr key={service.service.code}>
                        <td>{service.service.name}</td>
                        <td className="price">
                          {formatCurrency(service.totalAmount.grossAmount)}
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
              {fees.length > 0 && (
                <>
                  <tr className="price-group-header">
                    <td colSpan={2}>Taxes and Fees</td>
                  </tr>
                  {fees.map((fee, index) => {
                    return (
                      <tr key={index}>
                        <td>{fee.name}</td>
                        <td className="price">{formatCurrency(fee.amount)}</td>
                      </tr>
                    )
                  })}
                </>
              )}
            </>
          )}
          {/* item total price */}
          <tr className="price-summary-header">
            <td colSpan={2}>Total Charges</td>
          </tr>
          <tr>
            <td>Total Room Charges</td>
            <td className="font-medium price">
              {formatCurrency(totalRoomCharges)}
            </td>
          </tr>
          {additionalServices.length > 0 && (
            <tr>
              <td>Total Service Charges</td>
              <td className="font-medium price">
                {formatCurrency(totalServiceCharges)}
              </td>
            </tr>
          )}

          <tr>
            <td>Total Taxes and Fees</td>
            <td className="font-medium price">
              {formatCurrency(totalTaxesAndFees)}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Total Prepayment</td>
            <td className="font-bold price">
              {formatCurrency(totalPrepayment)}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Total</td>
            <td className="font-bold price">{formatCurrency(totalCharges)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ShoppingCartPricingDetails
