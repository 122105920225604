import {createSelector} from '@reduxjs/toolkit'
import {selectBooker} from './bookerSlice'
import {selectCartItems} from './shoppingCartSlice'
import {
  BookingReservation,
  CreateIbeBookingMetadata,
  ShoppingCartItem
} from '../types'

/**
 * Selects the booking metadata for the IBE booking.
 */
export const selectBookingMetadata = createSelector(
  [selectBooker, selectCartItems],
  (booker, cartItems): CreateIbeBookingMetadata => {
    const reservations: BookingReservation[] = cartItems.map(
      (cartItem: ShoppingCartItem) => {
        const {offer, additionalServices, adults} = cartItem

        const timeSlices = offer.timeSlices.map(_ => ({
          ratePlanId: offer.ratePlan?.id
        }))

        const services = additionalServices.map(service => ({
          serviceId: service.service?.id
        }))

        // TODO: add children ages
        return {
          arrival: offer.arrival,
          departure: offer.departure,
          adults,
          childrenAges: [],
          guestComment: booker.comments || '',
          channelCode: 'Ibe',
          primaryGuest: {
            firstName: booker.firstName,
            lastName: booker.lastName,
            email: booker.email,
            phone: booker.phone
          },
          guaranteeType: offer.minGuaranteeType,
          timeSlices,
          services,
          prePaymentAmount: {
            amount: offer.prePaymentAmount.amount,
            currency: offer.prePaymentAmount.currency
          }
        }
      }
    )

    return {
      type: 'CreateIbeBooking',
      booker: {
        firstName: booker.firstName,
        lastName: booker.lastName,
        email: booker.email,
        phone: booker.phone
      },
      reservations
    }
  }
)
