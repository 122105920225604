import React, {useState} from 'react'
import CheckoutGuestInfo from './CheckoutGuestInfo'
import CheckoutPayment from './CheckoutPayment'
import Breadcrumb from '../common/Breadcrumb'
import {AppView} from '../../types'
import ShoppingCart from '../cart/ShoppingCart'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import CheckoutProcessingModal from './CheckoutProcessingModal'
import {setAppView} from '../../store/appViewSlice'
import {selectIsValidBooker} from '../../store/bookerSlice'
import {clearCart} from '../../store/shoppingCartSlice'
import {clearSearch} from '../../store/searchSlice'

export interface CheckoutViewProps {
  appView: AppView
}

const CheckoutView = ({appView}: CheckoutViewProps) => {
  const dispatch = useAppDispatch()
  const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false)
  const [paymentSessionId, setPaymentSessionId] = useState<string>(
    undefined as any
  )
  const isValidBooker = useAppSelector(selectIsValidBooker)

  return (
    <section>
      <Breadcrumb appView={appView} />
      <section>
        <h1 className="text-xl font-bold">Checkout</h1>
        <div className="mt-4 flex flex-col lg:flex-row gap-8">
          <div className="flex flex-col gap-4 lg:w-1/2">
            <CheckoutGuestInfo />
            <CheckoutPayment
              isActive={isValidBooker}
              onPaymentProcessing={(paymentSessionId: string) => {
                setPaymentSessionId(paymentSessionId)
                setIsProcessingModalOpen(true)
              }}
            />
            <CheckoutProcessingModal
              isOpen={isProcessingModalOpen}
              onComplete={() => {
                setIsProcessingModalOpen(false)
                dispatch(clearCart())
                dispatch(clearSearch())
                dispatch(setAppView(AppView.CONFIRMATION))
              }}
              onClose={() => setIsProcessingModalOpen(false)}
              paymentSessionId={paymentSessionId}
            />
          </div>
          <div className="order-first lg:order-none lg:w-1/2">
            <section className="px-4 pb-4 rounded-xl border border-gray-300 bg-gray-100">
              <ShoppingCart />
            </section>
          </div>
        </div>
      </section>
    </section>
  )
}

export default CheckoutView
