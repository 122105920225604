import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IbeConfig} from '../types'

export const configSlice = createSlice({
  name: 'config',
  initialState: null as IbeConfig | null,
  reducers: {
    setConfig: (state: IbeConfig, action: PayloadAction<IbeConfig>) => {
      return action.payload
    }
  }
})

// selectors
export const selectConfig = (state: any): IbeConfig | null => {
  return state.config
}

export const selectPropertiesById = createSelector(
  [selectConfig],
  (config: IbeConfig) => {
    return config?.properties.reduce((acc, property) => {
      acc[property.id] = property
      return acc
    }, {} as Record<string, any>)
  }
)

export const selectPropertyById = createSelector(
  [selectPropertiesById, (_, propertyId: string) => propertyId],
  (properties, propertyId) => {
    return properties[propertyId]
  }
)

// actions
export const {setConfig} = configSlice.actions

// reducer
export default configSlice.reducer
