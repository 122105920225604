import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getConfig} from './config'
import {PaymentSessionRequest} from '../types'

const {adyenPaymentsApiUrl} = getConfig()

export const adyenPaymentsApi = createApi({
  reducerPath: 'adyenPaymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: adyenPaymentsApiUrl
  }),
  tagTypes: ['PaymentMethods', 'PaymentSession', 'PaymentSessionStatus'],
  endpoints: builder => ({
    getPaymentSession: builder.query({
      query(request: PaymentSessionRequest) {
        return {
          url: '/payment-session',
          method: 'POST',
          body: request
        }
      },
      providesTags: ['PaymentSession']
    }),
    getPaymentMethods: builder.query({
      query(propertyId: string) {
        return `/payment-methods?propertyId=${propertyId}`
      },
      providesTags: ['PaymentMethods'],
      keepUnusedDataFor: 120000
    }),
    getPaymentSessionStatus: builder.query({
      query(paymentSessionId: string) {
        return `/payment-session/status?paymentSessionId=${paymentSessionId}`
      },
      providesTags: ['PaymentSessionStatus']
    })
  })
})

// export hooks
export const {
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery,
  useGetPaymentSessionStatusQuery,
  useLazyGetPaymentMethodsQuery,
  useLazyGetPaymentSessionQuery,
  useLazyGetPaymentSessionStatusQuery
} = adyenPaymentsApi

// clear payment session cache
export function clearPaymentSession() {
  adyenPaymentsApi.util.invalidateTags(['PaymentSession'])
}
