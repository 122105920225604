import React from 'react'
import {GuestInfo} from './CheckoutGuestInfoForm'

export interface CheckoutGuestInfoDisplayProps {
  guestInfo: GuestInfo
  onEdit: () => void
}

const CheckoutGuestInfoDisplay = ({
  guestInfo,
  onEdit
}: CheckoutGuestInfoDisplayProps) => {
  return (
    <div className="flex flex-row gap-1 my-4">
      <div className="flex-1 flex flex-col gap-2">
        <div className="flex flex-col">
          <span className="text-xs font-semibold">Name:</span>
          <span>
            {guestInfo.firstName} {guestInfo.lastName}
          </span>
        </div>

        <div className="flex flex-col">
          <span className="text-xs font-semibold">Email:</span>
          <span>{guestInfo.email}</span>
        </div>

        <div className="flex flex-col">
          <span className="text-xs font-semibold">Phone:</span>
          <span>{guestInfo.phone}</span>
        </div>

        {guestInfo.comments && (
          <div className="flex flex-col">
            <span className="text-xs font-semibold">Comments:</span>
            <p>{guestInfo.comments}</p>
          </div>
        )}
      </div>
      <div className="flex-0">
        <button
          className="btn btn-sm btn-primary btn-outline"
          onClick={() => {
            if (onEdit) {
              onEdit()
            }
          }}
        >
          Edit
        </button>
      </div>
    </div>
  )
}

export default CheckoutGuestInfoDisplay
