import React, {useEffect} from 'react'
import {
  AdvancedMarker,
  InfoWindow,
  Map,
  Pin,
  useAdvancedMarkerRef,
  useMap
} from '@vis.gl/react-google-maps'
import clsx from 'clsx'
import {PropertyConfig} from '../../types'

export interface SearchGoogleMapProps {
  properties: PropertyConfig[]
  selectedProperty?: string
  className?: string
}

function getCssVariableValue(cssVariable: string) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(cssVariable)
    .trim()
}

const SearchGoogleMap = ({
  properties,
  selectedProperty,
  className = ''
}: SearchGoogleMapProps) => {
  const map = useMap()
  const [markerRef, marker] = useAdvancedMarkerRef()

  useEffect(() => {
    if (!map) {
      return
    }

    if (selectedProperty) {
      const property = properties.find(
        property => property.id === selectedProperty
      )
      if (property) {
        const [lat, lng] = property.latLong
        // map.setCenter(new window.google.maps.LatLng(lat, lng))
        map.panTo(new window.google.maps.LatLng(lat, lng))
        map.setZoom(15)
      }
    } else if (properties.length === 1) {
      const property = properties[0]
      const [lat, lng] = property.latLong
      // map.setCenter(new window.google.maps.LatLng(lat, lng))
      map.panTo(new window.google.maps.LatLng(lat, lng))
      map.setZoom(15)
    } else if (properties.length > 1) {
      const bounds = new window.google.maps.LatLngBounds()
      properties.forEach(property => {
        const [lat, lng] = property.latLong
        bounds.extend(new window.google.maps.LatLng(lat, lng))
      })
      map.fitBounds(bounds)
    }
  }, [map, properties, selectedProperty])

  return (
    <div className={clsx('card card-compact shadow-md', className)}>
      <div className="card-body p-1">
        <Map
          mapId="searchGoogleMap"
          style={{width: '400px', height: '100%', minHeight: '500px'}}
          defaultZoom={10}
          defaultCenter={{lat: 38.90343, lng: -77.0536}}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        >
          {properties.map((property, index) => {
            if (property.id === selectedProperty) {
              // render selected property with info window
              const address1 = property.address?.addressLine1
              const address2 = property.address?.addressLine2 || ''
              const city = property.address?.city
              const state =
                property.address?.state === 'DC'
                  ? null
                  : property.address?.state
              const postalCode = property.address?.postalCode

              return (
                <AdvancedMarker
                  key={index}
                  position={{
                    lat: property.latLong[0],
                    lng: property.latLong[1]
                  }}
                  ref={markerRef}
                >
                  <Pin
                    background="#122634"
                    glyphColor="#b2ece7"
                    borderColor="#b2ece7"
                  />

                  {selectedProperty === property.id && (
                    <InfoWindow
                      anchor={marker}
                      headerContent={
                        <div className="font-bold text-lg">{property.name}</div>
                      }
                    >
                      <div className="flex flex-col">
                        <div>{`${address1} ${
                          address2 ? ', ' + address2 : ''
                        }`}</div>
                        <div>{`${city}${
                          state ? ', ' + state : ''
                        } ${postalCode}`}</div>
                      </div>
                    </InfoWindow>
                  )}
                </AdvancedMarker>
              )
            } else {
              // render property marker
              return (
                <AdvancedMarker
                  key={index}
                  position={{
                    lat: property.latLong[0],
                    lng: property.latLong[1]
                  }}
                >
                  <Pin
                    background="#122634"
                    glyphColor="#b2ece7"
                    borderColor="#b2ece7"
                  />
                </AdvancedMarker>
              )
            }
          })}
        </Map>
      </div>
    </div>
  )
}

export default SearchGoogleMap
