import React, {useEffect, useRef} from 'react'
import {
  AdvancedMarker,
  InfoWindow,
  Map,
  Pin,
  useAdvancedMarkerRef,
  useMap
} from '@vis.gl/react-google-maps'
import {PropertyConfig} from '../../types'
import {config} from '@fortawesome/fontawesome-svg-core'
import {getConfig} from '../../services/config'

export interface SearchGoogleMapModalProps {
  properties?: PropertyConfig[]
  selectedProperty?: string
  isOpen: boolean
  onClose: () => void
}

const SearchGoogleMapModal = ({
  properties,
  selectedProperty,
  isOpen,
  onClose
}: SearchGoogleMapModalProps) => {
  const property = properties?.find(
    property => property.id === selectedProperty
  )

  // display nothing if property is not found
  if (!property) {
    return null
  }

  const {googleMapsApiKey} = getConfig()
  const address1 = property.address?.addressLine1
  const address2 = property.address?.addressLine2 || ''
  const city = property.address?.city
  const state =
    property.address?.state === 'DC' ? null : property.address?.state
  const postalCode = property.address?.postalCode
  const [latitude, longitude] = property.latLong
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=16&size=800x600&markers=color:red%7C${latitude},${longitude}&key=${googleMapsApiKey}`

  /*
                  <Pin
                    background="#122634"
                    glyphColor="#b2ece7"
                    borderColor="#b2ece7"
                  />

*/

  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box">
            <div className="flex flex-col">
              <div className="font-bold text-lg">{property.name}</div>
              <div>{`${address1} ${address2 ? ', ' + address2 : ''}`}</div>
              <div>{`${city}${state ? ', ' + state : ''} ${postalCode}`}</div>
            </div>

            <img className="mt-4 border p-1" src={mapUrl} />

            <div className="modal-action">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  onClose()
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

// const SearchGoogleMapModal = ({
//   properties,
//   selectedProperty,
//   isOpen,
//   onClose
// }: SearchGoogleMapModalProps) => {
//   const mapId = 'searchGoogleMapModal'
//   const [isInitialized, setIsInitialized] = React.useState(false)
//   const [markerRef, marker] = useAdvancedMarkerRef()

//   const property = properties?.find(
//     property => property.id === selectedProperty
//   )

//   // map does not render when the containing div is hidden
//   // so we need to trigger a resize event when the modal is opened
//   useEffect(() => {
//     // if (isOpen && mapRef?.current) {
//     //   window.google.maps.event.trigger(mapRef.current, 'resize')
//     // }

//     setTimeout(() => setIsInitialized(true), 1000)
//   }, [isOpen])

//   // console.log('map modal', {
//   //   selectedProperty,
//   //   property,
//   //   properties,
//   //   isOpen
//   // })

//   // display nothing if property is not found
//   if (!property) {
//     return null
//   }

//   const address1 = property.address?.addressLine1
//   const address2 = property.address?.addressLine2 || ''
//   const city = property.address?.city
//   const state =
//     property.address?.state === 'DC' ? null : property.address?.state
//   const postalCode = property.address?.postalCode

//   return (
//     <>
//       {isOpen && (
//         <div
//           className="modal modal-open"
//           onClick={e => {
//             if (e.target === e.currentTarget) {
//               onClose()
//             }
//           }}
//         >
//           <div className="modal-box">
//             <h2 className="font-bold text-lg">Property Map</h2>
//             {isInitialized ? (
//               <Map
//                 mapId={mapId}
//                 style={{width: '100%', height: '100%', minHeight: '500px'}}
//                 zoom={16}
//                 center={{
//                   lat: property.latLong[0],
//                   lng: property.latLong[1]
//                 }}
//                 gestureHandling={'greedy'}
//                 disableDefaultUI={true}
//               >
//                 <AdvancedMarker
//                   position={{
//                     lat: property.latLong[0],
//                     lng: property.latLong[1]
//                   }}
//                   ref={markerRef}
//                 >
//                   <Pin
//                     background="#122634"
//                     glyphColor="#b2ece7"
//                     borderColor="#b2ece7"
//                   />
//                   <InfoWindow
//                     anchor={marker}
//                     headerContent={
//                       <div className="font-bold text-lg">{property.name}</div>
//                     }
//                   >
//                     <div className="flex flex-col">
//                       <div>{`${address1} ${
//                         address2 ? ', ' + address2 : ''
//                       }`}</div>
//                       <div>{`${city}${
//                         state ? ', ' + state : ''
//                       } ${postalCode}`}</div>
//                     </div>
//                   </InfoWindow>
//                 </AdvancedMarker>
//               </Map>
//             ) : (
//               <></>
//             )}

//             <div className="modal-action">
//               <button
//                 className="btn btn-secondary"
//                 onClick={() => {
//                   onClose()
//                 }}
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   )
// }

export default SearchGoogleMapModal
