// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IBE Styles */
body {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1,oklch(var(--b1)/var(--tw-bg-opacity)));
}

/* Checkout summary table */
.table tr {
  border-bottom-color: transparent;
}

td.tax-line-item {
  padding-top: 0;
  padding-bottom: 0;
}

/* price details table */
table.price-details {
  width: 100%;
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  table.price-details {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

table.price-details th,
td {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

table.price-details td.price {
  text-align: right;
}

table.price-details tr.price-group-header {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  font-weight: 500;
}

table.price-details tr.price-summary-header td {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  font-weight: 700;
}

`, "",{"version":3,"sources":["webpack://./src/styles/ibe.css"],"names":[],"mappings":"AAAA,eAAe;AAEb;EAAA,kBAAkB;EAAlB;AAAkB;;AAGpB,2BAA2B;AAC3B;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA,wBAAwB;AAEtB;EAAA,WAAgC;EAAhC,kBAAgC;EAAhC;AAAgC;AAAhC;EAAA;IAAA,mBAAgC;IAAhC;EAAgC;AAAA;;AAKhC;;EAAA,qBAAW;EAAX;AAAW;;AAIX;EAAA;AAAiB;;AAIjB;EAAA,kBAA8B;EAA9B,yDAA8B;EAA9B;AAA8B;;AAI9B;EAAA,kBAA4B;EAA5B,yDAA4B;EAA5B;AAA4B","sourcesContent":["/* IBE Styles */\nbody {\n  @apply bg-base-100;\n}\n\n/* Checkout summary table */\n.table tr {\n  border-bottom-color: transparent;\n}\n\ntd.tax-line-item {\n  padding-top: 0;\n  padding-bottom: 0;\n}\n\n/* price details table */\ntable.price-details {\n  @apply text-xs md:text-sm w-full;\n}\n\ntable.price-details th,\ntd {\n  @apply px-1;\n}\n\ntable.price-details td.price {\n  @apply text-right;\n}\n\ntable.price-details tr.price-group-header {\n  @apply bg-gray-200 font-medium;\n}\n\ntable.price-details tr.price-summary-header td {\n  @apply bg-gray-200 font-bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
