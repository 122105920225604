import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useEffect} from 'react'
import * as types from '../../types'
import clsx from 'clsx'
import {useAppDispatch} from '../../store/hooks'
import {removeToastNotification} from '../../store/toastNotificationSlice'

export interface ToastNotificationProps {
  notification: types.ToastNotification
}

const autoCloseDuration = 3000

const ToastNotification = ({notification}: ToastNotificationProps) => {
  const dispatch = useAppDispatch()

  // autoclose toast notification after 10 seconds
  useEffect(() => {
    const timerRef = setTimeout(() => {
      dispatch(removeToastNotification(notification))
    }, autoCloseDuration)

    return () => {
      clearTimeout(timerRef)
    }
  }, [notification])

  return (
    <div
      className={clsx('alert border shadow', {
        'alert-success': notification.type === 'success',
        'alert-warning': notification.type === 'warning',
        'alert-error': notification.type === 'error',
        'alert-info': !['success', 'warning', 'error'].includes(
          notification.type
        )
      })}
    >
      <button
        className="btn btn-ghost btn-circle btn-sm"
        onClick={() => {
          dispatch(removeToastNotification(notification))
        }}
      >
        <FontAwesomeIcon icon={faXmark} className="text-lg" />
      </button>
      <span>{notification.message}</span>
    </div>
  )
}

export default ToastNotification
