import React, {useEffect, useState} from 'react'
import Badge from '../common/Badge'
import CheckoutGuestInfoForm, {GuestInfo} from './CheckoutGuestInfoForm'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectHasMemberRate} from '../../store/shoppingCartSlice'
import MemberAuthenticator, {
  MemberAuthenticatorView
} from '../auth/MemberAuthenticator'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons'
import {
  clearMember,
  selectIsAuthenticated,
  selectMember
} from '../../store/memberSlice'
import {
  selectBooker,
  selectIsValidBooker,
  setBooker
} from '../../store/bookerSlice'
import CheckoutGuestInfoDisplay from './CheckoutGuestInfoDisplay'
import {Auth} from 'aws-amplify'
import {set} from 'date-fns'

enum GuestInfoView {
  Choose = 'Choose',
  Login = 'Login',
  Register = 'Register',
  Form = 'Form',
  EditForm = 'EditForm',
  Display = 'Display'
}

const CheckoutGuestInfo = () => {
  const dispatch = useAppDispatch()
  const [guestInfoView, setGuestInfoView] = useState<GuestInfoView>(
    GuestInfoView.Choose
  )
  const booker = useAppSelector(selectBooker)
  const member = useAppSelector(selectMember)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isValidBooker = useAppSelector(selectIsValidBooker)
  const cartHasMemberRate = useAppSelector(selectHasMemberRate)

  useEffect(() => {
    if (cartHasMemberRate && !isAuthenticated) {
      // cart has member rates - require checkout as member
      setGuestInfoView(GuestInfoView.Choose)
    } else if (isValidBooker) {
      // booker is valid, show the guest info
      setGuestInfoView(GuestInfoView.Display)
    } else if (!isValidBooker && isAuthenticated) {
      // booker is invalid but user is authenticated, show the prepopulated form
      setGuestInfoView(GuestInfoView.Form)
    } else {
      // default to choose
      setGuestInfoView(GuestInfoView.Choose)
    }
  }, [isValidBooker, isAuthenticated, cartHasMemberRate])

  return (
    <div className="border border-1 border-gray-300 rounded-xl p-4 ">
      <div className="flex flex-row gap-4">
        <Badge label="1" />
        <h1 className="font-bold">Guest Info</h1>
      </div>

      {guestInfoView === GuestInfoView.Choose && (
        <div className="flex flex-col gap-4 mt-4">
          {!cartHasMemberRate && (
            <button
              className="btn btn-secondary"
              onClick={() => {
                setGuestInfoView(GuestInfoView.Form)
              }}
            >
              Checkout as Guest
            </button>
          )}
          <button
            className="btn btn-secondary"
            onClick={() => {
              setGuestInfoView(GuestInfoView.Login)
            }}
          >
            Login as Member
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setGuestInfoView(GuestInfoView.Register)
            }}
          >
            Register New Member
          </button>
        </div>
      )}
      {(guestInfoView === GuestInfoView.Login ||
        guestInfoView === GuestInfoView.Register) && (
        <>
          <MemberAuthenticator
            className="mt-4"
            view={
              guestInfoView === GuestInfoView.Register
                ? MemberAuthenticatorView.SignUp
                : MemberAuthenticatorView.SignIn
            }
          />
          {!member.isAuthenticated && (
            <div className="flex justify-center w-full mt-2">
              <a
                className="link link-primary text-sm"
                onClick={() => {
                  setGuestInfoView(GuestInfoView.Choose)
                }}
              >
                <FontAwesomeIcon icon={faCaretLeft} className="mr-1" />
                <span>back</span>
              </a>
            </div>
          )}
        </>
      )}
      {(guestInfoView === GuestInfoView.Form ||
        guestInfoView === GuestInfoView.EditForm) && (
        <CheckoutGuestInfoForm
          className="mt-4"
          isEditMode={guestInfoView === GuestInfoView.EditForm}
          member={member}
          booker={booker}
          onBack={() => {
            setGuestInfoView(GuestInfoView.Choose)
          }}
          onCancel={() => {
            setGuestInfoView(GuestInfoView.Display)
          }}
          onSignOut={() => {
            Auth.signOut()
            dispatch(clearMember())
          }}
          onSave={(guestInfo: GuestInfo) => {
            dispatch(setBooker(guestInfo))
            setGuestInfoView(GuestInfoView.Display)
          }}
        />
      )}

      {guestInfoView === GuestInfoView.Display && (
        <CheckoutGuestInfoDisplay
          guestInfo={booker}
          onEdit={() => {
            setGuestInfoView(GuestInfoView.EditForm)
          }}
        />
      )}
    </div>
  )
}

export default CheckoutGuestInfo
