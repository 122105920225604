import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction
} from '@reduxjs/toolkit'
import {getConfig} from '../services/config'
import appViewReducer from './appViewSlice'
import bookerReducer from './bookerSlice'
import configReducer from './configSlice'
import memberReducer from './memberSlice'
import searchReducer from './searchSlice'
import shoppingCartReducer from './shoppingCartSlice'
import toastNotificationReducer from './toastNotificationSlice'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import {adyenPaymentsApi} from '../services/adyenPaymentsApi'
import {ibeApi} from '../services/ibeApi'
import {getLogger} from '../services/logging'
import {createPersistReducer} from './persist'

const {reduxDevTools} = getConfig()
const logger = getLogger('ReduxStore')

const combinedReducer = combineReducers({
  appView: appViewReducer,
  booker: bookerReducer,
  config: configReducer,
  member: memberReducer,
  search: searchReducer,
  shoppingCart: shoppingCartReducer,
  toastNotification: toastNotificationReducer,
  [adyenPaymentsApi.reducerPath]: adyenPaymentsApi.reducer,
  [ibeApi.reducerPath]: ibeApi.reducer
})

let rootState: RootState

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  rootState = combinedReducer(state, action)

  // log all changes to redux state
  logger.debug({
    message: 'Redux state changed',
    data: {action, state: rootState, previousState: state}
  })

  return rootState
}

// create redux persist reducer - persists state to local storage
const persistentReducer = createPersistReducer(rootReducer)

// create redux store
export const store = configureStore({
  reducer: persistentReducer,
  middleware: getDefaultMiddleware => {
    // ignore non-serializable actions
    const persistOptions = {
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }
    return getDefaultMiddleware(persistOptions).concat([
      ibeApi.middleware,
      adyenPaymentsApi.middleware
    ])
  },
  devTools: reduxDevTools
})

// create redux persistor
export const persistor = persistStore(store)

/**
 * Gets the root state of the redux store
 * @returns the root state of the redux store
 */
export function getRootState() {
  return rootState
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
