import React from 'react'
import {IbeConfig, UnitGroupOffer} from '../../types'
import SearchUnitAttributes from './SearchUnitAttributes'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import SearchUnitOfferItem from './SearchUnitOfferItem'
import ImageCarousel from '../common/ImageCarousel'
import SearchUnitDetailsModal from './SearchUnitDetailsModal'
import clsx from 'clsx'

export interface SearchUnitResultItemProps {
  config: IbeConfig
  propertyId: string
  unitGroupOffer: UnitGroupOffer
}

const AVAILABLE_UNITS_THRESHOLD = 5

function getAvailableUnitsLabel(availableUnits: number): string | undefined {
  if (availableUnits === 1) {
    return 'Only 1 room left'
  } else if (availableUnits <= AVAILABLE_UNITS_THRESHOLD) {
    return `Only ${availableUnits} rooms left`
  } else {
    return
  }
}

const SearchUnitResultItem = ({
  config,
  propertyId,
  unitGroupOffer
}: SearchUnitResultItemProps) => {
  const [showDetailsModal, setShowDetailsModal] = React.useState(false)
  const {unitGroup, availableUnits, offers, nights} = unitGroupOffer
  const sortedOffers = offers.sort((x, y) => {
    return x.totalGrossAmount.amount - y.totalGrossAmount.amount
  })

  return (
    <>
      <div className="card bg-white shadow">
        <div className="flex flex-col md:flex-row">
          {/* image carousel */}
          <div
            className={clsx(
              'w-full overflow-hidden rounded-tl-2xl rounded-tr-2xl max-h-72',
              'md:w-5/12 md:rounded-bl-2xl md:rounded-tr-none md:max-h-none'
            )}
          >
            <ImageCarousel />
          </div>

          <div className="w-full md:w-7/12">
            {/* unit header */}
            <div className="w-full border-b flex flex-col gap-1 p-4">
              <h1 className="text-2xl font-bold">{unitGroup.name}</h1>
              <SearchUnitAttributes />

              <a
                className="link link-primary text-sm"
                onClick={() => {
                  setShowDetailsModal(true)
                }}
              >
                Room Details
              </a>
            </div>

            {/* unit low availability warning */}
            {availableUnits < AVAILABLE_UNITS_THRESHOLD && (
              <div className="flex flex-row justify-end px-4 pt-4">
                <div className="text-red-500 text-sm">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="mr-1"
                  />
                  <span>{getAvailableUnitsLabel(availableUnits)}</span>
                </div>
              </div>
            )}

            {/* unit offers */}
            <div className="w-full flex flex-col gap-8 p-4">
              {sortedOffers.map((offer, index) => {
                return (
                  <SearchUnitOfferItem
                    key={index}
                    offer={offer}
                    propertyId={propertyId}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <SearchUnitDetailsModal
        config={config}
        unitGroup={unitGroupOffer.unitGroup}
        isOpen={showDetailsModal}
        onClose={() => {
          setShowDetailsModal(false)
        }}
      />
    </>
  )
}

export default SearchUnitResultItem
