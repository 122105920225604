import React from 'react'
import {Authenticator} from '@aws-amplify/ui-react'
import {Auth} from 'aws-amplify'

// -----------------------------------------------------------------------------
//  Authenticator Form Fields
// -----------------------------------------------------------------------------
const formFields = {
  signIn: {
    username: {
      label: 'Username (email)',
      labelHidden: false,
      placeholder: 'Enter your email',
      order: 1
    },
    password: {
      label: 'Password',
      labelHidden: false,
      placeholder: 'Enter your password',
      order: 2
    }
  },
  signUp: {
    username: {
      label: 'Username (email)',
      labelHidden: false,
      placeholder: 'Enter your email',
      order: 1
    },
    given_name: {
      label: 'First Name',
      labelHidden: false,
      placeholder: 'Enter your first name',
      order: 2
    },
    family_name: {
      label: 'Last Name',
      labelHidden: false,
      placeholder: 'Enter your last name',
      order: 3
    },
    phone: {
      label: 'Phone (Mobile)',
      labelHidden: false,
      placeholder: 'Enter your mobile phone number',
      order: 4
    },
    password: {
      label: 'Password',
      labelHidden: false,
      placeholder: 'Enter your password',
      order: 5
    },
    confirm_password: {
      label: 'Confirm Password',
      labelHidden: false,
      placeholder: 'Confirm your password',
      order: 6
    }
  },
  forceNewPassword: {
    password: {
      label: 'Password',
      labelHidden: false,
      placeholder: 'Enter your Password'
    }
  },
  resetPassword: {
    username: {
      label: 'Username (email)',
      labelHidden: false,
      placeholder: 'Enter your email'
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      label: 'Confirmation Code',
      labelHidden: false,
      placeholder: 'Enter your confirmation code',
      order: 1
    },
    password: {
      label: 'Password',
      labelHidden: false,
      placeholder: 'Enter your password',
      order: 2
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Enter your password',
      order: 3
    }
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'Confirmation Code',
      labelHidden: false,
      placeholder: 'Enter your confirmation code'
    }
  }
}

// -----------------------------------------------------------------------------
//  Authenticator Service Functions
// -----------------------------------------------------------------------------
const services = {
  async handleSignUp(formData: any) {
    // convert email/username to lowercase
    const {username, password, attributes} = formData
    const lowerUsername = username.toLowerCase()
    return Auth.signUp({
      username: lowerUsername,
      password,
      attributes,
      autoSignIn: {
        enabled: true
      }
    })
  },

  async handleSignIn(formData: any) {
    // convert username to lowercase
    const {username, password} = formData
    const lowerUsername = username.toLowerCase()
    return Auth.signIn({
      username: lowerUsername,
      password
    })
  }
}

export enum MemberAuthenticatorView {
  SignIn = 'signIn',
  SignUp = 'signUp'
}

export interface MemberAuthenticatorProps {
  className?: string
  view?: MemberAuthenticatorView
}

const MemberAuthenticator = ({
  className = '',
  view = MemberAuthenticatorView.SignIn
}: MemberAuthenticatorProps) => {
  return (
    <div className={className}>
      <Authenticator
        initialState={view}
        services={services}
        formFields={formFields}
      />
    </div>
  )
}

export default MemberAuthenticator
