import {
  faBiking,
  faBuilding,
  faUmbrellaBeach
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'

interface SearchPropertyAmenitiesProps {
  className?: string
}

const SearchPropertyAmenities = ({className}: SearchPropertyAmenitiesProps) => {
  return (
    <div className={className}>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-0.5 pt-1 text-sm text-cabana">
        <div>
          <FontAwesomeIcon icon={faBiking} className="mr-1" />
          <span>Fitness Center</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faBuilding} className="mr-1" />
          <span>Rooftop Lounge</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faUmbrellaBeach} className="mr-1" />
          <span>Onsite Rental</span>
        </div>
      </div>
    </div>
  )
}

export default SearchPropertyAmenities
