import React from 'react'
import {PropertyConfig} from '../../types'
import ImageCarousel from '../common/ImageCarousel'
import SearchPropertyAmenities from './SearchPropertyAmenities'

export interface SearchPropertyDetailsModalProps {
  propertyConfig: PropertyConfig
  isOpen: boolean
  onClose: () => void
}

const SearchPropertyDetailsModal = ({
  propertyConfig,
  isOpen,
  onClose
}: SearchPropertyDetailsModalProps) => {
  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box w-full p-0">
            <div className="w-full h-72 rounded-t-2xl overflow-hidden">
              <ImageCarousel />
            </div>
            <button
              className="btn btn-sm btn-circle btn-white absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>
            <div className="modal-body overflow-y-scroll max-h-96 pr-4 mt-2 px-8 py-4">
              <h2 className="font-bold text-xl mb-2">{propertyConfig?.name}</h2>
              <SearchPropertyAmenities />
              <h3 className="font-bold text-cabana uppercase mt-4 mb-2">
                Features
              </h3>
              <ul className="list-disc px-5 grid grid-cols-2 gap-0">
                <li>Pool</li>
                <li>Gym</li>
                <li>Rooftop</li>
                <li>On-site Parking</li>
                <li>Workspaces</li>
                <li>Lounge</li>
                <li>Bar</li>
                <li>Ice Machines</li>
              </ul>
              <div className="modal-action">
                <button className="btn" onClick={onClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SearchPropertyDetailsModal
