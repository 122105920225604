import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface MemberState {
  isAuthenticated: boolean
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

const initialState: MemberState = {
  isAuthenticated: false
}

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setMember: (state, action: PayloadAction<MemberState>) => {
      state = action.payload
      return state
    },
    clearMember: state => {
      state = initialState
      return state
    }
  }
})

// selectors
export const selectMember = (state: any): MemberState => {
  return state.member
}

export const selectIsAuthenticated = createSelector(
  [selectMember],
  (member: MemberState) => {
    return member.isAuthenticated
  }
)

// actions
export const {setMember, clearMember} = memberSlice.actions

// reducer
export default memberSlice.reducer
