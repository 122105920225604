import React from 'react'
import {Offer} from '../../types'
import SearchUnitOfferDetailsModal from './SearchUnitOfferDetailsModal'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {showItemEditor} from '../../store/shoppingCartSlice'
import {generateUUID} from '../../services/utils'
import clsx from 'clsx'
import {selectGuestsPerRoom} from '../../store/searchSlice'

export interface SearchUnitOfferItemProps {
  propertyId: string
  offer: Offer
}

function formatOfferDescription(offer: Offer): string {
  const {prePaymentAmount, cancellationFee, noShowFee} = offer
  const prePayDescription =
    prePaymentAmount.amount > 0
      ? `Prepayment amount of ${prePaymentAmount.amount} must be made at the time of booking.`
      : 'No prepayment required.'
  const cancellationDescription = cancellationFee.description + '.'
  const noShowDescription = noShowFee.description + '.'

  return [prePayDescription, cancellationDescription, noShowDescription].join(
    ' '
  )
}

// TODO: Use something other than rate plan name to detect member rates
function isMemberRate(offer: Offer): boolean {
  return offer.ratePlan.name.toLowerCase().includes('member')
}

const SearchUnitOfferItem = ({offer, propertyId}: SearchUnitOfferItemProps) => {
  const dispatch = useAppDispatch()
  const guestsPerRoom = useAppSelector(selectGuestsPerRoom)
  const [showOfferDetailsModal, setShowOfferDetailsModal] = React.useState(
    false
  )
  const {totalGrossAmount, timeSlices} = offer
  const nights = timeSlices.length
  const avgPerNight = Math.round(totalGrossAmount.amount / nights)

  return (
    <>
      <div className="w-full">
        {isMemberRate(offer) && (
          <span className="badge badge-accent mb-1">member rate</span>
        )}
        <div className={clsx('flex flex-col gap-4 w-full', 'lg:flex-row')}>
          <div className={clsx('w-full', 'lg:w-3/5')}>
            <h1 className="font-semibold text-md">{offer.ratePlan.name}</h1>
            <p className="mt-2 text-sm">{formatOfferDescription(offer)}</p>
            <div className="mt-1">
              <a
                className="link link-primary text-sm"
                onClick={() => {
                  setShowOfferDetailsModal(true)
                }}
              >
                Rate Details
              </a>
            </div>
          </div>
          <div
            className={clsx(
              'w-full flex gap-4 flex-row items-end',
              'lg:w-2/5 lg:items-start lg:flex-row'
            )}
          >
            <div className="flex-1 text-right">
              <div className="font-bold">
                <span className="text-xl leading-6 mr-1">
                  ${offer.totalGrossAmount.amount}
                </span>
                <span>{offer.totalGrossAmount.currency}</span>
              </div>
              <div className="text-gray-400 text-xs leading-3">
                total for {nights} nights
              </div>
              <div className="text-gray-400 text-xs leading-3 pt-0.5">
                ${avgPerNight} / night (avg)
              </div>
            </div>
            <div className="flex-0 flex flex-row justify-end">
              <button
                className="w-48 lg:w-24 btn btn-secondary"
                onClick={() => {
                  // show item editor modal
                  dispatch(
                    showItemEditor({
                      item: {
                        id: generateUUID(),
                        propertyId,
                        offer,
                        additionalServices: [],
                        adults: guestsPerRoom
                      },
                      mode: 'Add'
                    })
                  )
                }}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>

      <SearchUnitOfferDetailsModal
        offer={offer}
        isOpen={showOfferDetailsModal}
        onClose={() => setShowOfferDetailsModal(false)}
      />
    </>
  )
}

export default SearchUnitOfferItem
