import React from 'react'
import {useAppDispatch} from '../../store/hooks'
import {setAppView} from '../../store/appViewSlice'
import {AppView} from '../../types'

const ConfirmationView = () => {
  const dispatch = useAppDispatch()
  return (
    <div className="flex flex-col gap-2 items-center justify-center h-screen text-center">
      <h1 className="text-2xl font-bold">Your booking is complete!</h1>
      <p>A confirmation email was sent to your email address.</p>
      <a
        className="link link-primary"
        onClick={() => {
          dispatch(setAppView(AppView.HOME))
        }}
      >
        Go Back
      </a>
    </div>
  )
}

export default ConfirmationView
