import React, {useState} from 'react'
import {removeItem, showItemEditor} from '../../store/shoppingCartSlice'
import {useAppDispatch} from '../../store/hooks'
import * as types from '../../types'
import HotelRoom from '../../assets/hotel-room.png'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronRight,
  faEdit,
  faRemove
} from '@fortawesome/free-solid-svg-icons'
import ShoppingCartPricingDetails from './ShoppingCartPricingDetails'
import {formatDate, generateUUID} from '../../services/utils'
import {addToastNotification} from '../../store/toastNotificationSlice'

export interface ShoppingCartItemProps {
  item: types.ShoppingCartItem
  itemIndex?: number
  showItemIndex?: boolean
  showActions?: boolean
  showPricingDetails?: boolean
  className?: string
}

const ShoppingCartItem = ({
  item,
  itemIndex = -1,
  showItemIndex = false,
  showActions = false,
  showPricingDetails = false,
  className
}: ShoppingCartItemProps) => {
  const dispatch = useAppDispatch()
  const [showFullDetails, setShowFullDetails] = useState(showPricingDetails)

  return (
    <div
      className={clsx(
        'card card-compact border bg-white shadow w-full',
        className
      )}
    >
      <div className="card-body">
        {/* card header */}
        <div className="flex flex-col gap-1">
          {showItemIndex && (
            <div className="badge badge-primary">Room {itemIndex + 1}</div>
          )}
          <div className="flex flex-row gap-2 w-full">
            <div className="flex-1">
              <h3 className="font-bold text-md md:text-xl">
                {item.offer.unitGroup.name}
              </h3>
              <div className="text-xs md:text-sm">
                {item.offer.ratePlan.name}
              </div>
              <div className="text-xs md:text-sm">
                {formatDate(item.offer.arrival)} -{' '}
                {formatDate(item.offer.departure)}
              </div>
            </div>

            {/* item actions */}
            {showActions && (
              <div className="flex-0 flex flex-col items-end md:gap-2 md:flex-row md:items-center">
                <a
                  className="link link-primary text-xs md:text-sm flex flex-row gap-1 items-center"
                  onClick={() => {
                    dispatch(removeItem(item.id))
                    dispatch(
                      addToastNotification({
                        id: generateUUID(),
                        type: 'success',
                        message: 'Removed room from shopping cart.'
                      })
                    )
                  }}
                >
                  <FontAwesomeIcon icon={faRemove} />
                  <span>Remove Room</span>
                </a>
                <a
                  className="link link-primary text-xs md:text-sm flex flex-row gap-1 items-center"
                  onClick={() => {
                    dispatch(showItemEditor({item, mode: 'Edit'}))
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <span>Edit Room</span>
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="w-full flex flex-row justify-end">
          <a
            className="link link-primary text-2xs md:text-xs"
            onClick={() => setShowFullDetails(!showFullDetails)}
          >
            <FontAwesomeIcon
              icon={showFullDetails ? faChevronDown : faChevronRight}
              className="mr-1"
            />
            <span>{showFullDetails ? 'Hide' : 'Show'} full details</span>
          </a>
        </div>

        <div className="flex flex-row gap-2 w-full">
          <div className="w-2/5 flex flex-col gap-2">
            {/* item image */}
            <div className="w-full">
              <img
                src={HotelRoom}
                alt={item.offer.unitGroup.name}
                className="object-cover rounded"
              />
            </div>
          </div>

          <div className="w-3/5 flex flex-col items-end gap-2">
            {/* item price details */}
            <ShoppingCartPricingDetails
              item={item}
              showFullDetails={showFullDetails}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartItem
