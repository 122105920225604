import React from 'react'
import clsx from 'clsx'
import {AppView} from '../../types'
import {useAppDispatch} from '../../store/hooks'
import {setAppView} from '../../store/appViewSlice'

export interface BreadcrumbProps {
  appView: string
  className?: string
}

const Breadcrumb = ({appView, className = ''}: BreadcrumbProps) => {
  const dispatch = useAppDispatch()

  if (appView === AppView.HOME) {
    return
  }

  return (
    <div className={clsx('text-sm breadcrumbs', className)}>
      <ul>
        <li>
          <a
            className="link"
            onClick={() => dispatch(setAppView(AppView.HOME))}
          >
            Home
          </a>
        </li>
        {appView === AppView.CHOOSE_PROPERTY && <li>Choose Property</li>}
        {appView === AppView.CHOOSE_UNIT && (
          <>
            <li>
              <a
                className="link"
                onClick={() => dispatch(setAppView(AppView.CHOOSE_PROPERTY))}
              >
                Choose Property
              </a>
            </li>
            <li>Choose Room</li>
          </>
        )}
        {appView === AppView.CHECKOUT && (
          <>
            <li>
              <a
                className="link"
                onClick={() => dispatch(setAppView(AppView.CHOOSE_PROPERTY))}
              >
                Choose Property
              </a>
            </li>
            <li>
              <a
                className="link"
                onClick={() => dispatch(setAppView(AppView.CHOOSE_UNIT))}
              >
                Choose Room
              </a>
            </li>
            <li>Checkout</li>
          </>
        )}
      </ul>
    </div>
  )
}

export default Breadcrumb
