import React, {useState, useEffect, useRef} from 'react'
import {useLazyGetPaymentSessionStatusQuery} from '../../services/adyenPaymentsApi'
import {PaymentSessionStatus} from '../../types'
import clsx from 'clsx'

enum View {
  Loading = 'Loading',
  Error = 'Error'
}

// polling interval in ms and max attempts
const POLL_INTERVAL = 15000
const POLL_MAX_ATTEMPTS = 10

export interface CheckoutProcessingModalProps {
  isOpen: boolean
  onComplete: () => void
  onClose: () => void
  paymentSessionId?: string
}

const CheckoutProcessingModal = ({
  isOpen,
  onComplete,
  onClose,
  paymentSessionId
}: CheckoutProcessingModalProps) => {
  const [view, setView] = useState<View>(View.Loading)
  const pollAttempts = useRef(0)
  const [getPaymentSessionStatus] = useLazyGetPaymentSessionStatusQuery()

  useEffect(() => {
    if (!paymentSessionId) {
      return
    }

    const intervalId = setInterval(async () => {
      console.log(`Polling for payment session status - ${paymentSessionId}`)

      if (pollAttempts.current >= POLL_MAX_ATTEMPTS) {
        console.log('Max polling attempts reached, stop polling for status')
        clearInterval(intervalId)
        setView(View.Error)
        return
      } else {
        pollAttempts.current += 1
      }

      getPaymentSessionStatus(paymentSessionId)
        .then(response => {
          const status = response.data?.status as PaymentSessionStatus
          console.log('Retrieved payment session status:', status)
          switch (status) {
            case PaymentSessionStatus.PaymentSucceeded:
              console.log('Payment succeeded, stop polling for status')
              clearInterval(intervalId)
              onComplete()
              break
            case PaymentSessionStatus.PaymentRejected:
            case PaymentSessionStatus.PaymentFailed:
              console.log('Payment failed, stop polling for status')
              clearInterval(intervalId)
              setView(View.Error)
              break
            default:
              console.log('Continue polling for status...')
          }
        })
        .catch(() => {
          console.log(
            'Failed to retrieve payment session status, stop polling for status'
          )
          clearInterval(intervalId)
          setView(View.Error)
        })
    }, POLL_INTERVAL)

    return () => clearInterval(intervalId)
  }, [paymentSessionId])

  return (
    <div className={clsx('modal', {'modal-open': isOpen})}>
      <div className="modal-box">
        <div className="modal-body w-full p-0">
          {view === View.Loading && (
            <div className="flex flex-col items-center justify-center gap-4 h-96">
              <span className="loading loading-spinner loading-lg"></span>
              <h2 className="text-xl font-bold">Processing...</h2>
              <p className="text-center mx-4">
                Please wait while we process your payment and create your
                booking. This may take a few minutes.
              </p>
            </div>
          )}

          {view === View.Error && (
            <div className="flex flex-col items-center justify-center gap-4 h-96">
              <h2 className="text-xl font-bold">Booking Failed</h2>
              <p className="text-center mx-4">
                The booking could not be processed because your payment failed.
                You may try again with a different payment method. If problems
                persist, please contact us.
              </p>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    onClose()
                  }}
                >
                  Try Again
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckoutProcessingModal
