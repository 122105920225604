import React, {useState} from 'react'
import clsx from 'clsx'

interface NumberStepperProps {
  value: number
  min?: number
  max?: number
  step?: number
  onChange?: (value: number) => void
  className?: string
}

const NumberStepper: React.FC<NumberStepperProps> = ({
  value,
  min = 0,
  max = 100,
  step = 1,
  onChange = (value: number) => {},
  className = ''
}) => {
  return (
    <div className={clsx('flex flex-row items-center gap-2', className)}>
      <a
        className={clsx('btn btn-circle btn-xs', {
          'btn-disabled': value <= min
        })}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()

          const updatedValue = value - step
          if (updatedValue >= min) {
            onChange(updatedValue)
          }
        }}
      >
        -
      </a>
      <span>{value}</span>
      <a
        className={clsx('btn btn-circle btn-xs', {
          'btn-disabled': value >= max
        })}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()

          const updatedValue = value + step
          if (updatedValue <= max) {
            onChange(updatedValue)
          }
        }}
      >
        +
      </a>
    </div>
  )
}

export default NumberStepper
