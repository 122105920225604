import {LogLevel} from '../types'
import {getConfig} from './config'

const {loggingEnabled, loggingLevel, loggingApiUrl} = getConfig()

export interface LogMessage {
  level?: LogLevel
  message: string
  data?: any
}

export interface ErrorLogMessage extends LogMessage {
  errorCode?: string
  errorData?: any
  application?: string
  component?: string
}

/**
 * @class Logger
 * @classdesc Logger implementation interfaces with an API backend for server-persisted UI logging
 */
class Logger {
  private readonly application: string = 'ibe-frontend'
  private readonly component: string = ''
  private buffer: LogMessage[] = []
  private maxBuffer: number = 10
  private maxTime: number = 120000
  private timer: any = null

  public constructor(component: string) {
    this.component = component
  }

  private isLoggableLevel(logLevel: LogLevel | undefined): boolean {
    if (!logLevel) {
      return false
    }

    const loggingLevelValue = {
      [LogLevel.DEBUG]: 3,
      [LogLevel.INFO]: 2,
      [LogLevel.WARN]: 1,
      [LogLevel.ERROR]: 0
    }
    const logValue = loggingLevelValue[logLevel] || 0
    const logValueThreshold = loggingLevelValue[loggingLevel]
    return logValue <= logValueThreshold
  }

  private async logRequest(logMessages: LogMessage[]): Promise<void> {
    try {
      await fetch(loggingApiUrl, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': '*/*'
        }),
        body: JSON.stringify(logMessages)
      })
    } catch (e) {
      console.error('Logging error', e)
    }
  }

  private async flush(): Promise<void> {
    // clear the buffer timer
    clearTimeout(this.timer)
    this.timer = null

    if (this.buffer.length === 0) {
      console.log('No buffered log messages to flush.')
      return
    }

    try {
      console.log('Flushing buffered log messages...', {buffer: this.buffer})

      // send http request
      await this.logRequest(this.buffer)

      // clear the buffer
      this.buffer = []

      console.log('Successfully flushed buffered log messages.')
    } catch (e) {
      console.error('Failed to flush buffered log messages.')

      // reset the timer and try again
      this.timer = setTimeout(() => this.flush(), this.maxTime)
    }
  }

  private async send(logMessage: LogMessage): Promise<void> {
    this.buffer.push(logMessage)

    // flush buffer immediately if any error log messages occur
    if (logMessage.level === LogLevel.ERROR) {
      await this.flush()
    }

    // flush buffer if max time is exceeded
    if (this.buffer.length === 1) {
      this.timer = setTimeout(() => this.flush(), this.maxTime)
    }

    // flush buffer if max buffer size is exceeded
    if (this.buffer.length >= this.maxBuffer) {
      await this.flush()
    }
  }

  private async log(logMessage: LogMessage): Promise<void> {
    try {
      switch (logMessage.level) {
        case LogLevel.DEBUG:
          console.debug(logMessage)
          break
        case LogLevel.INFO:
          console.info(logMessage)
          break
        case LogLevel.WARN:
          console.warn(logMessage)
          break
        case LogLevel.ERROR:
          console.error(logMessage)
          break
        default:
          console.info(logMessage)
      }

      if (loggingEnabled && this.isLoggableLevel(logMessage?.level)) {
        const {application, component} = this
        const message = {
          ...logMessage,
          timestamp: new Date().toISOString(),
          data: {
            ...logMessage.data,
            application,
            component
          }
        }
        await this.send(message)
      }
    } catch (e) {
      // suppress errors, fallback on non-JSON format
      console.warn('Logging ')
      console.log(logMessage)
    }
  }

  public async debug(params: LogMessage): Promise<void> {
    await this.log({
      ...params,
      level: LogLevel.DEBUG
    })
  }

  public async info(params: LogMessage): Promise<void> {
    await this.log({
      ...params,
      level: LogLevel.INFO
    })
  }

  public async warn(params: LogMessage): Promise<void> {
    await this.log({
      ...params,
      level: LogLevel.WARN
    })
  }

  public async error(params: ErrorLogMessage): Promise<void> {
    await this.log({
      ...params,
      level: LogLevel.ERROR
    })
  }
}

export function getLogger(component = 'global'): Logger {
  return new Logger(component)
}
