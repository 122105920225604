import React, {useEffect} from 'react'
import SearchBar from './SearchBar'
import SearchPropertyResults from '../search-property/SearchPropertyResults'
import {AppView, IbeConfig, SearchParams} from '../../types'
import {useParallelSearchOffersQuery} from '../../hooks/useParallelSearchOffersQuery'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectSearch, setSearch} from '../../store/searchSlice'
import {getPropertiesByLocation} from '../../services/utils'
import SearchUnitResults from '../search-unit/SearchUnitResults'
import {setAppView} from '../../store/appViewSlice'
import Hero from '../../assets/hero-1.png'
import Breadcrumb from '../common/Breadcrumb'

interface SearchViewProps {
  appView: AppView
  config: IbeConfig
}

const SearchView = ({appView, config}: SearchViewProps) => {
  const dispatch = useAppDispatch()
  const searchParams = useAppSelector(selectSearch)
  const {
    offerResults,
    loading,
    triggerParallelFetch
  } = useParallelSearchOffersQuery()

  useEffect(() => {
    if (searchParams && searchParams.location !== 'ALL') {
      const properties = getPropertiesByLocation(
        config.properties,
        searchParams.location
      )

      const {dateRange, guests, specialRates} = searchParams
      const requests = properties.map(property => {
        return {
          propertyId: property.id,
          dateRange,
          guests,
          specialRates
        }
      })
      triggerParallelFetch(requests)
    }
  }, [searchParams])

  return (
    <section>
      <Breadcrumb appView={appView} className="mb-2" />
      <SearchBar
        config={config}
        searchParams={searchParams}
        onSubmit={(searchParams: SearchParams) => {
          dispatch(setSearch(searchParams))
          dispatch(setAppView(AppView.CHOOSE_PROPERTY))
        }}
      />

      {appView === AppView.HOME && (
        <div
          className="hero h-[500px] mt-4 rounded-2xl border"
          style={{
            backgroundImage: `url(${Hero})`
          }}
        >
          <div className="hero-overlay bg-opacity-75 rounded-2xl"></div>
          <div className="hero-content text-neutral-content text-center">
            <div className="max-w-md">
              <h1 className="mb-5 text-5xl font-bold font-serif">
                Welcome to Placemakr
              </h1>
              <p className="mb-5 text-xl">
                Your stay, your way. Kick off your shoes and enjoy the comforts
                of a place that feels just like home.
              </p>
              <p className="mb-5">
                Sign up today for member-only perks. More comfort, more rewards,
                every time you stay.
              </p>
              <button className="btn btn-primary">Become a Member</button>
            </div>
          </div>
        </div>
      )}

      {appView === AppView.CHOOSE_PROPERTY && (
        <SearchPropertyResults
          config={config}
          searchParams={searchParams}
          offerResults={offerResults}
          isLoading={loading}
          onSelectProperty={(propertyId: string) => {
            dispatch(setSearch({...searchParams, propertyId}))
            dispatch(setAppView(AppView.CHOOSE_UNIT))
          }}
        />
      )}

      {appView === AppView.CHOOSE_UNIT && (
        <SearchUnitResults
          config={config}
          offerResults={offerResults}
          selectedPropertyId={searchParams.propertyId || ''}
          isLoading={loading}
          onSelectRoom={(room: any) => {
            alert('Room selected')
          }}
        />
      )}
    </section>
  )
}

export default SearchView
