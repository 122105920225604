import React, {useEffect, useState} from 'react'
import {IbeConfig, OfferResult, PropertyConfig, SearchParams} from '../../types'
import SearchPropertyResultItem from './SearchPropertyResultItem'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMapLocationDot} from '@fortawesome/free-solid-svg-icons'
import SearchGoogleMap from './SearchGoogleMap'
import SearchGoogleMapModal from './SearchGoogleMapModal'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import clsx from 'clsx'
import SearchPropertyDetailsModal from './SearchPropertyDetailsModal'

export interface SearchPropertyResultsProps {
  config: IbeConfig
  isLoading: boolean
  searchParams: SearchParams
  offerResults?: OfferResult[]
  onSelectProperty: (propertyId: string) => void
}

export interface SearchMapState {
  selectedProperty?: string
  properties?: PropertyConfig[]
}

const SearchPropertyResults = ({
  config,
  isLoading,
  searchParams,
  offerResults = [],
  onSelectProperty
}: SearchPropertyResultsProps) => {
  const windowDimensions = useWindowDimensions()
  const [isMapVisible, setIsMapVisible] = useState(true)
  const [isMapModalOpen, setIsMapModalOpen] = useState(false)
  const [searchMapState, setSearchMapState] = useState<SearchMapState>({
    properties: []
  })
  const {properties, locations} = config
  const propertyLookup = properties.reduce(
    (acc: Record<string, PropertyConfig>, property: PropertyConfig) => {
      acc[property.id] = property
      return acc
    },
    {}
  )

  const location = locations.find(
    location => location.id === searchParams.location
  )

  useEffect(() => {
    if (offerResults.length > 0) {
      const propertyConfigs = offerResults.map(result => {
        return propertyLookup[result.property.id]
      })
      setSearchMapState({
        ...searchMapState,
        properties: propertyConfigs
      })
    }
  }, [config, offerResults])

  if (isLoading) {
    return (
      <div className="flex flex-row gap-8 my-4">
        <div className="flex-1 flex flex-col gap-8 ">
          <div className="skeleton h-64 w-full"></div>
          <div className="skeleton h-64 w-full"></div>
          <div className="skeleton h-64 w-full"></div>
        </div>
        {windowDimensions.isXL && (
          <div className="skeleton h-64 w-[400px]"></div>
        )}
      </div>
    )
  } else if (!isLoading && offerResults.length > 0) {
    return (
      <>
        <div className="flex flex-row justify-center items-center gap-4 mt-4">
          <div className="flex-1 text-lg">
            Showing {offerResults.length} property results for{' '}
            <span className="font-bold">{location?.name}</span>:
          </div>
          {windowDimensions.isXL && (
            <div className="flex-none">
              <a
                className="link link-primary text-sm"
                onClick={() => {
                  setIsMapVisible(!isMapVisible)
                }}
              >
                <FontAwesomeIcon icon={faMapLocationDot} className="mr-1" />
                <span>{isMapVisible ? 'hide' : 'show'} map</span>
              </a>
            </div>
          )}
        </div>

        <div className="flex flex-row gap-4">
          <div className="flex-1 flex flex-col gap-8 mt-4 mb-8 h-screen">
            <div
              className={clsx('flex flex-col gap-8', {
                'overflow-y-scroll pb-1 pr-3':
                  isMapVisible && windowDimensions.isXL
              })}
            >
              {offerResults.map((result, index) => {
                const propertyConfig = propertyLookup[result.property.id]
                return (
                  <SearchPropertyResultItem
                    key={index}
                    result={result}
                    propertyConfig={propertyConfig}
                    showMapLink={!windowDimensions.isXL}
                    onShowOnMap={(propertyId: string) => {
                      setSearchMapState({
                        ...searchMapState,
                        selectedProperty: propertyId
                      })
                      if (
                        !windowDimensions.isSmall ||
                        !windowDimensions.isMedium ||
                        !windowDimensions.isLarge ||
                        !isMapVisible
                      ) {
                        setIsMapModalOpen(true)
                      }
                    }}
                    onHover={(propertyId: string) => {
                      if (windowDimensions.isXL || isMapVisible) {
                        setSearchMapState({
                          ...searchMapState,
                          selectedProperty: propertyId
                        })
                      }
                    }}
                    onSelectProperty={onSelectProperty}
                  />
                )
              })}
            </div>
          </div>
          {windowDimensions.isXL &&
            isMapVisible &&
            searchMapState.properties && (
              <SearchGoogleMap
                className="mt-4 mb-8 bg-white h-screen pb-2"
                selectedProperty={searchMapState?.selectedProperty}
                properties={searchMapState.properties}
              />
            )}
        </div>

        <SearchGoogleMapModal
          selectedProperty={searchMapState?.selectedProperty}
          properties={searchMapState?.properties}
          isOpen={isMapModalOpen}
          onClose={() => setIsMapModalOpen(false)}
        />
      </>
    )
  } else {
    return <div className="my-8">No results found.</div>
  }
}

export default SearchPropertyResults
