import React from 'react'
import {useAppSelector} from '../../store/hooks'
import {selectToastNotifications} from '../../store/toastNotificationSlice'
import ToastNotification from './ToastNotification'

const ToastNotificationManager = () => {
  const toastNotifications = useAppSelector(selectToastNotifications)
  return (
    <div className="toast toast-bottom toast-end z-50">
      {toastNotifications.map((notification, index) => (
        <ToastNotification key={index} notification={notification} />
      ))}
    </div>
  )
}

export default ToastNotificationManager
