import {useState, useEffect} from 'react'

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
}

export interface WindowDimensions {
  width: number
  height: number
  isSmall: boolean
  isMedium: boolean
  isLarge: boolean
  isXL: boolean
  is2XL: boolean
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const getBreakpointValues = (width: number) => ({
    isSmall: width >= breakpoints.sm,
    isMedium: width >= breakpoints.md,
    isLarge: width >= breakpoints.lg,
    isXL: width >= breakpoints.xl,
    is2XL: width >= breakpoints['2xl']
  })

  const handleResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    ...windowDimensions,
    ...getBreakpointValues(windowDimensions.width)
  } as WindowDimensions
}

export default useWindowDimensions
